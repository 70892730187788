import React from 'react';
import './ContactPage.css';

import ContactForm from './ContactForm';

class ContactPage extends React.Component {
    render() {
        return (
            <>
                <div className="content-wrapper-contact">
  <div className="contact-summary-container">
    <div className="info-list">
      <div className="info-list-item">
        <div className="header-line"></div>
        <h2>CONTACT</h2>
        <h4>HAIDEȚI SĂ LUĂM LEGĂTURA!</h4>
        <p>
          Aveți o întrebare despre vehiculele noastre utilitare, sau aveți un proiect în minte care necesită expertiza noastră? Nu ezitați să ne contactați! Suntem întotdeauna gata să discutăm orice întrebări sau idei pe care le-ați putea avea. Nu ezitați să ne trimiteți un mesaj oricând. Așteptăm cu nerăbdare să auzim de la dvs!
        </p>
        <ContactForm />
      </div>
    </div>
  </div>
  <div className="info-list-horizontal">
    <div className="info-list-item">
      <div className="header-line"></div>
      <h4>PUTEREA COMUNICĂRII CLARE</h4>
      <p>
        La EcoComTech, credem că o comunicare clară, eficientă și respectuoasă este piatra de temelie a tuturor interacțiunilor de succes. Fie că aveți o întrebare rapidă despre vehiculele noastre utilitare sau doriți să aveți o discuție aprofundată despre nevoile dvs. specifice, valorizăm și ne străduim pentru claritate și înțelegere în fiecare schimb. Haideți să ne conectăm și să împărtășim idei în mod eficient - pentru că lucruri mari se întâmplă atunci când comunicăm bine!
      </p>
    </div>
  </div>
</div>
            </>
        );
    }
}
export default ContactPage;
