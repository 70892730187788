import React from 'react';
import './BlogPage.css';

import { Helmet } from 'react-helmet';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://www.yourdomain.com';


class BlogPage extends React.Component {
    render() {
        return (
            <>
                <div className="content-wrapper-blog">
                    <div className="resume-summary-container">
                        <div className="info-list">
                            <h3>BLOG</h3>
                            <div className="info-list-item">
                                <div className="header-line"></div>
                                <article>
                                    <Helmet>
                                        <title>Prezentăm cel mai recent vehicul utilitar: EcoComTech Beast!</title>
                                        <meta name="description" content="Suntem încântați să anunțăm lansarea celui mai recent vehicul utilitar, EcoComTech Beast. Acest vehicul este proiectat să facă față celor mai dificile terenuri și celor mai solicitante sarcini." />
                                        <meta property="og:title" content='Prezentăm cel mai recent vehicul utilitar: EcoComTech Beast!' />
                                        <meta property="og:description" content="Suntem încântați să anunțăm lansarea celui mai recent vehicul utilitar, EcoComTech Beast. Acest vehicul este proiectat să facă față celor mai dificile terenuri și celor mai solicitante sarcini." />
                                        <meta property="og:image" content={`${BASE_URL}/logo.png`} />
                                    </Helmet>
                                    <h2>Prezentăm cel mai recent vehicul utilitar: EcoComTech Beast!</h2>
                                    Suntem încântați să anunțăm lansarea celui mai recent vehicul utilitar, EcoComTech Beast. Acest vehicul este proiectat să facă față celor mai dificile terenuri și celor mai solicitante sarcini. Cu motorul său puternic, construcția robustă și caracteristicile inovatoare, EcoComTech Beast este gata să preia orice provocare. Abia așteptăm să experimentați puterea și versatilitatea pe care le oferă.
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default BlogPage;
