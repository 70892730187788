import React from 'react';
import './SkillsPage.css';


class SkillsPage extends React.Component {
    render() {
        return (
            <>
                <div className="content-wrapper-skills">
                    <div className="skills-summary-container">
                        <div className="info-list">
                            <h3>COMPETENȚE</h3>
                            <div className="info-list-item">
                                <div className="header-line"></div>
                                <h2>DESIGN VEHICULE</h2>
                                Suntem mândri de capacitatea noastră de a proiecta vehicule utilitare care sunt nu doar funcționale, dar și inovatoare. Designurile noastre sunt conduse de o înțelegere profundă a nevoilor clienților noștri și de cele mai recente avansări în tehnologia vehiculelor.
                            </div>
                        </div>
                    </div>
                    <div className="skills-summary-container">
                        <div className="info-list">
                            <div className="info-list-item">
                                <div className="header-line"></div>
                                <h2>FABRICAȚIE</h2>
                                Procesul nostru de fabricație este optimizat și eficient, asigurând că fiecare vehicul pe care îl producem îndeplinește standardele noastre înalte de calitate. Utilizăm echipamente și tehnici de ultimă oră pentru a construi vehicule durabile și de încredere.
                            </div>
                        </div>
                    </div>
                    <div className="skills-summary-container">
                        <div className="info-list">
                            <div className="info-list-item">
                                <div className="header-line"></div>
                                <h2>SOLUȚII PERSONALIZATE</h2>
                                Înțelegem că fiecare client are nevoi unice. De aceea oferim soluții personalizate, adaptând vehiculele noastre utilitare pentru a satisface cerințele specifice ale fiecărui client. Fie că aveți nevoie de un vehicul pentru construcții, agricultură sau transport, putem oferi o soluție potrivită.
                            </div>
                        </div>
                    </div>
                    <div className="skills-summary-container">
                        <div className="info-list">
                            <div className="info-list-item">
                                <div className="header-line"></div>
                                <h2>SERVICII ȘI ÎNTREȚINERE</h2>
                                Oferim servicii și întreținere complete pentru toate vehiculele noastre utilitare. Echipa noastră de tehnicieni calificați asigură că vehiculul dvs. rămâne în condiții optime, minimizând timpii de inactivitate și maximizând productivitatea.
                            </div>
                        </div>
                    </div>
                    <div className="skills-summary-container">
                        <div className="info-list">
                            <div className="info-list-item">
                                <div className="header-line"></div>
                                <h2>INOVARE</h2>
                                În inima companiei noastre se află un angajament pentru inovație. Suntem în căutare continuă de noi tehnologii și idei pentru a îmbunătăți vehiculele noastre utilitare și a oferi clienților noștri cele mai bune produse posibile.
                            </div>
                        </div>
                    </div>
                    <div className="skills-summary-container">
                        <div className="info-list">
                            <div className="info-list-item">
                                <div className="header-line"></div>
                                <h2>SUSTENABILITATE</h2>
                                Ne dedicăm creării de vehicule utilitare care sunt nu doar eficiente și eficace, dar și prietenoase cu mediul. Credem în a face partea noastră pentru a crea un viitor sustenabil.
                            </div>
                        </div>
                    </div>
                    <div className="info-list-horizontal">
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h2>HAIDEȚI CU NOI ÎN ACEASTĂ CĂLĂTORIE</h2>
                            Pe măsură ce continuăm să creștem și să inovăm, vă invităm să vă alăturați nouă în această călătorie. Fie că sunteți client, partener sau membru al comunității noastre, suntem încântați să lucrăm împreună pentru a crea vehicule utilitare care fac diferența. Să conducem viitorul împreună!
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
export default SkillsPage;
