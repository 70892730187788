import React from 'react';
import './TestimonialsPage.css';


class TestimonialsPage extends React.Component {
    render() {
        return (
            <>
                <div className="content-wrapper-testimonials">
                    <div className="testimonials-summary-container">
                        <div className="info-list">
                            <h3>MĂRTURII</h3>
                            <div className="info-list-item">
                                <div className="header-line"></div>
                                <h2>UN CLIENT FIDEL</h2>
                                "Ca manager de flotă, am colaborat cu numeroase companii, dar serviciile Ecocomtech sunt de neegalat. Vehiculele lor utilitare nu doar că satisfac toate cerințele noastre, dar echipa lor de asistență este întotdeauna pregătită să ne ajute. Sunt cu adevărat impresionat de angajamentul lor pentru calitate și inovație."
                            </div>
                        </div>
                    </div>

                    <div className="info-list-horizontal">
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h2>UN PARTENER DE AFACERI</h2>
                            "Colaborarea noastră cu Ecocomtech a transformat modul în care gestionăm logistica. Eficiența și fiabilitatea vehiculelor lor ne-a permis să îmbunătățim semnificativ operațiunile noastre. Recomand cu încredere serviciile lor excepționale."
                        </div>
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h2>UN INGINER MECANIC</h2>
                            "Lucrând în industria utilajelor grele, am văzut multe vehicule utilitare, dar cele de la Ecocomtech sunt cu un pas înainte. Inovațiile lor tehnologice și atenția la detalii m-au impresionat profund."
                        </div>
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h2>UN UTILIZATOR FINAL</h2>
                            "Folosesc vehiculele Ecocomtech în agricultură și sunt extrem de mulțumit de performanța lor. Sunt robuste, eficiente și ușor de întreținut. Este clar că pun pasiune în tot ceea ce fac."
                        </div>
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h2>UN CONSULTANT DE MEDIU</h2>
                            "Ecocomtech nu doar că oferă vehicule utilitare de înaltă calitate, dar se angajează și în practici sustenabile. Eforturile lor pentru a reduce impactul asupra mediului sunt admirabile și exemplare în industrie."
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
export default TestimonialsPage;
