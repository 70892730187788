import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import PortfolioPage from './components/PortfolioPage';
import BlogPage from './components/BlogPage';
import TestimonialsPage from './components/TestimonialsPage';
import SkillsPage from './components/SkillsPage';
import ServicesPage from './components/ServicesPage';
import ContactPage from './components/ContactPage';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';



class App extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>ecocomtech - Web & Game Developer</title>
          <meta name="description" content="Showcasing the web and game development portfolio of ecocomtech. Explore innovative projects and creative designs." />
          <meta name="keywords" content="ecocomtech, Web Developer, Game Developer, Portfolio, Web Development, Game Design" />
          <meta property="og:title" content="ecocomtech - Web & Game Developer" />
          <meta property="og:description" content="Discover the portfolio of ecocomtech, where web development meets game design. Learn about my projects, skills, and professional journey." />
          <meta property="og:image" content="https://ecocomtech.ro/ogimage.jpg" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://ecocomtech.ro/" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="ecocomtech - Web & Game Developer" />
          <meta name="twitter:description" content="Portfolio of ecocomtech: From web applications to immersive games, discover a unique blend of technology and creativity." />
          <meta name="twitter:image" content="https://ecocomtech.ro/twitterimage.jpg" />
          <link rel="canonical" href="https://ecocomtech.ro/" />
          <link rel="icon" href="https://ecocomtech.ro/favicon.ico" />
          {/* Additional tags as needed */}
        </Helmet>
        <Router>
          <div className="master-frame">
            <header>
              <div className="header-content">

                <div className="header-content-top">
                  <div className="header-logo">
                    <a href="http://www.ecocomtech.ro" target="_blank">
                      <img src="logo.png" alt="ecocomtech Logo" width="100px" />
                    </a>
                  </div>
                  <div className="header-title">
                    <h1>ecocomtech.ro</h1>
                  </div>
                </div>

                <div className="header-content-bottom">
                  <nav className="header-nav">
                    <div className="header-menu">
                      <div className="header-menu-item"><Link to="/">Acasă</Link></div>
                      <div className="header-menu-item"><Link to="/about">Despre noi</Link></div>
                      <div className="header-menu-item"><Link to="/portfolio">Portofoliu</Link></div>
                      <div className="header-menu-item"><Link to="/blog">Blog</Link></div>
                      <div className="header-menu-item"><Link to="/testimonials">Testimoniale</Link></div>
                      <div className="header-menu-item"><Link to="/skills">Competențe</Link></div>
                      <div className="header-menu-item"><Link to="/services">Servicii</Link></div>
                      <div className="header-menu-item"><Link to="/contact">Contact</Link></div>
                    </div>
                  </nav>
                </div>

              </div>
              <div className="header-foot-line"></div>
            </header>

            <main>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/portfolio" element={<PortfolioPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/testimonials" element={<TestimonialsPage />} />
                <Route path="/skills" element={<SkillsPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/contact" element={<ContactPage />} />
              </Routes>
            </main>

            <footer>
              <div className="footer-head-line"></div>
              <p>&copy; All rights reserved ecocomtech.ro</p>
            </footer>
          </div>
        </Router>
      </>
    );
  }
}
export default App;
