import React from 'react';
import './HomePage.css';

class HomePage extends React.Component {
    render() {
        return (
            <>
                <div className="content-wrapper-home">
                    <div className="info-list">
                        <h3>Noutăţi</h3>
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h2>Misiunea Noastră</h2>
                            🚚Specializați în vehicule utilitare, suntem pasionați de a oferi soluții de top pentru nevoile afacerii dumneavoastră. Excelăm în a oferi o gamă largă de vehicule utilitare, combinând expertiza tehnică cu o înțelegere profundă a cerințelor industriei. Dedicat inovării și îmbogățirii pieței vehiculelor utilitare.🚛
                        </div>
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h2>Rămâneți Conectați</h2>
                            <p>Alăturați-vă rețelei noastre profesionale pe <a href="https://www.linkedin.com/in/ecocomtech/" target="_blank" rel="noopener noreferrer">LinkedIn 🌐</a> și să schimbăm idei!</p>
                        </div>
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h2>Inovație în Vehicule Utilitare</h2>
                            <p>Explorați designurile noastre unice de vehicule utilitare și proiectele inovatoare pe <a href="https://ecocomtech.ro/" target="_blank" rel="noopener noreferrer">site-ul nostru 🚚</a>.</p>
                        </div>
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h2>Prezentări pe YouTube</h2>
                            <p>Abonați-vă și alăturați-ne în călătoria noastră la <a href="https://youtube.com/@ecocomtech" target="_blank" rel="noopener noreferrer">YouTube ▶️</a> pentru actualizări regulate și perspective.</p>
                        </div>
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h2>Interacționați cu Noi</h2>
                            <p>Urmăriți actualizările noastre și interacționați cu noi pe <a href="https://twitter.com/ecocomtech" target="_blank" rel="noopener noreferrer">Twitter 🐦</a>. Să ne conectăm!</p>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default HomePage;
