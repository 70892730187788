import React from 'react';
import './AboutPage.css';



class AboutPage extends React.Component {
    render() {
        return (
            <>
                <div className="content-wrapper-about">
                    <div className="info-list">
                        <h3>DESPRE NOI</h3>
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <div className="header-title">
                                <img src="profile1B.jpg" alt="UtilityVehiclesCo" className='profile-picture' />
                                <h2 className='title-text'>ECOCOMTECH</h2>
                            </div>
                            Cu peste un deceniu în industria vehiculelor utilitare, ne specializăm în furnizarea de vehicule utilitare de top pentru toate nevoile afacerii dumneavoastră. Excelăm în rezolvarea provocărilor complexe de transport și inovăm pe piața vehiculelor utilitare. Dincolo de acest lucru, suntem pasionați de crearea de vehicule utilitare eficiente și fiabile. De asemenea, suntem fascinați de cele mai recente tehnologii din industria auto, explorând și integrându-le constant în produsele noastre. Angajați în învățare pe tot parcursul vieții, ne menținem la curent cu cele mai recente tendințe și tehnologii din industria vehiculelor utilitare și subliniem calitatea, fiabilitatea și satisfacția clientului în toate proiectele noastre.
                        </div>
                    </div>

                </div>
            </>
        );
    }
}
export default AboutPage;
