import React from 'react';
import './ServicesPage.css';


class ServicesPage extends React.Component {
    render() {
        return (
            <>
                <div className="content-wrapper-services">
                    <div className="services-summary-container">
                        <div className="info-list">
                            <div className="info-list-item">
                                <div className="header-line"></div>
                                <h2>SERVICII</h2>
                                <h4>EXPERȚI ÎN VEHICULE UTILITARE</h4>
                                <p>
                                    Suntem specializați în furnizarea de soluții pentru vehicule utilitare, combinând inovația cu eficiența. Fiecare proiect este un angajament pentru calitate și durabilitate.
                                </p>
                                <p>
                                    De la camioane de colectare a gunoiului la vehicule de întreținere, abordăm fiecare nevoie specifică cu o soluție adaptată. Misiunea noastră este de a oferi vehicule care nu doar îndeplinesc cerințele, dar și contribuie la un mediu mai curat.
                                </p>
                                <p>
                                    Pentru noi, fiecare vehicul utilitar pe care îl furnizăm reprezintă un pas înainte spre un viitor mai sustenabil. Aceasta este pasiunea care ne ghidează în fiecare zi.
                                </p>
                                <p>
                                    Dacă sunteți în căutarea unui partener de încredere în domeniul vehiculelor utilitare, contactați-ne. Suntem aici pentru a vă ajuta!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="info-list-horizontal">
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h4>TEHNOLOGIE AVANSATĂ</h4>
                            <p>
                                Tehnologia noastră de ultimă oră asigură că fiecare vehicul utilitar pe care îl producem este la standardele cele mai înalte de performanță și fiabilitate.
                            </p>
                            <p>
                                Ne dedicăm inovației continue pentru a ne asigura că clienții noștri beneficiază de cele mai eficiente și ecologice soluții de pe piață.
                            </p>
                            <p>
                                Fiecare vehicul este testat riguros pentru a garanta o funcționare optimă în cele mai solicitante condiții.
                            </p>
                        </div>
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h4>SUSTENABILITATE ȘI RESPONSABILITATE</h4>
                            <p>
                                Ne angajăm să contribuim la un viitor mai verde prin dezvoltarea de vehicule utilitare care reduc impactul asupra mediului.
                            </p>
                            <p>
                                Strategia noastră se concentrează pe reducerea emisiilor și creșterea eficienței energetice, fără a compromite performanța.
                            </p>
                            <p>
                                Prin colaborarea cu clienții noștri, dezvoltăm soluții personalizate care nu doar răspund nevoilor lor specifice, dar și promovează un mediu mai curat și mai sănătos.
                            </p>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
export default ServicesPage;
