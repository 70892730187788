import React from 'react';
import './PortfolioPage.css';


class PortfolioPage extends React.Component {
    render() {
        return (
            <>
                <div className="content-wrapper-services">
                    <div className="services-summary-container">
                        <div className="info-list">
                            <div className="info-list-item">
                                <div className="header-line"></div>
                                <h2>PORTOFOLIU</h2>
                                <h4>UTILITY VEHICLES EXPERTISE</h4>
                                <p>
                                    At EcoComTech, we specialize in utility vehicles. Our portfolio is a testament to our expertise and dedication in this field. We have a wide range of vehicles designed to meet various needs, from construction and agriculture to transportation and logistics.
                                </p>
                                <p>
                                    Each project we undertake is a blend of innovation, functionality, and reliability. We dive into the intricacies of design and engineering, transforming ideas into tangible, high-performance vehicles. These intense sessions are where our passion meets profession, and every challenge is an opportunity to innovate.
                                </p>
                                <p>
                                    For us, utility vehicle development isn't just a job; it's a passion lived out in these exhilarating, innovation-driven marathons. It's in this harmony of design and engineering that we find our true calling as creators, collaborators, and innovators.
                                </p>
                                <p>
                                    Feel free to explore our portfolio – we're always ready to discuss any inquiries or ideas you might have!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="info-list-horizontal">
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h4>CONDUCÂND SCHIMBAREA PENTRU O LUME MAI BUNĂ</h4>
                            <p>
                                La EcoComTech, credem în a avea un impact pozitiv prin munca noastră. Vehiculele noastre utilitare sunt concepute având în vedere durabilitatea și eficiența, contribuind la un viitor mai verde și mai sustenabil.
                            </p>
                            <p>
                                Suntem atrași de ideea de a crea vehicule care nu numai că îndeplinesc scopuri practice, dar contribuie și la un bine mai mare. Fie că este vorba de proiectarea motoarelor mai eficiente din punct de vedere al consumului de combustibil sau de utilizarea materialelor ecologice, obiectivul nostru este întotdeauna de a impulsiona schimbarea pozitivă.
                            </p>
                            <p>
                                Nu este vorba despre urmărirea tendințelor sau bifarea casetelor. Este vorba despre a face parte din ceva mai mare, ceva transformativ. Experiențele noastre ne-au învățat că a avea un impact pozitiv nu beneficiază doar lumea; îmbogățește munca noastră, luminează perspectiva noastră și adaugă un strat de împlinire misiunii noastre.
                            </p>
                            <p>
                                Așadar, dacă căutați vehicule utilitare care nu sunt doar funcționale, dar și durabile și eficiente, nu ezitați să ne contactați. Să colaborăm pentru a transforma viziunile extraordinare în realitate și a conduce un pic mai mult bine în lume.
                            </p>
                            <p>
                                Împreună, putem fi factorii de schimbare, inovatorii în acțiune. Să creăm unde de pozitivitate, un proiect extraordinar la un moment dat.
                            </p>
                        </div>
                        <div className="info-list-item">
                            <div className="header-line"></div>
                            <h4>"NU NE VOM LIMITA DOAR LA REPARAREA VEHICULULUI TĂU"</h4>
                            <p>
                                La EcoComTech, facem mai mult decât să reparăm vehicule. Inovăm, îmbunătățim, transformăm. Echipa noastră este dedicată oferirii de soluții care depășesc ordinarul, creând vehicule utilitare care fac cu adevărat diferența.
                            </p>
                            <p>
                                De ce să te mulțumești cu ceva obișnuit când te așteaptă ceva extraordinar? Munca noastră, oricât de grea ar fi, este o călătorie unică în viață. Ne datorăm nouă înșine să urmăm căi care ne aduc bucurie, împlinire și un sentiment de realizare. Repararea vehiculelor? Asta e doar începutul.
                            </p>
                            <p>
                                Să ne concentrăm pe ceea ce face inimile noastre profesionale să bată mai repede. Să ne îmbarcăm în călătorii care alimentează motoarele noastre creative, nu le epuizează. Să ne amintim, avem o singură viață de trăit; să nu o petrecem în partea superficială a piscinei tehnologice. În schimb, să ne scufundăm adânc în apele inovației și adevăratului avans tehnologic.
                            </p>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}
export default PortfolioPage;
